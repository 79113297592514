import i18n from "@/i18n";
import * as _ from "lodash";
import {i18ToSelect} from "@/tscript/utils/index";

export const getSexList = (forSelect = false) => {
  let types: any = {
    M: i18n.t("global.male"),
    F: i18n.t("global.feminine"),
  };
  if (forSelect) {
    types = i18ToSelect(types);
  }
  return types;
};

export const getCountriesList = (countriesList: any, withFlag = false) => {
  let countriesFormatted = new Array();
  if (countriesList?.length > 0) {
    countriesFormatted = countriesList.map((country: any) => {
      const obj: any = {};
      obj.value = country.value;
      obj.text = withFlag ? country.flag + " " + country.name?.common : country.name?.common;
      return obj;
    });
    countriesFormatted = _.sortBy(countriesFormatted, (o) => o.value);
  }
  return countriesFormatted;
};

export const codeToCountry = (countryCode: string, countriesList: any) => {
  const countryFinded = countriesList.find((country: any) => country.value == countryCode);
  const countryString = countryFinded?.label;
  return countryString;
};

export const getContactMethodsList = (smsDisable: boolean, forSelect = false) => {
  let itemsContactMethods = {};
  if (smsDisable) {
    itemsContactMethods = {
      phone_direct: i18n.t("global.phone_direct"),
      email: i18n.t("global.email"),
    };
  } else {
    itemsContactMethods = {
      phone_direct: i18n.t("global.phone_direct"),
      sms: i18n.t("global.sms"),
      email: i18n.t("global.email"),
      sms_email: i18n.t("global.sms_email"),
    };
  }
  if (forSelect) {
    itemsContactMethods = i18ToSelect(itemsContactMethods);
  }
  return itemsContactMethods;
};

export const getLanguagesList = (forSelect = false) => {
  let languages: any = {en: i18n.t("navbar.english"), pt: i18n.t("navbar.portuguese"), fr: i18n.t("navbar.french"), es: i18n.t("navbar.spanish")};
  if (forSelect) {
    languages = i18ToSelect(languages);
  }
  return languages;
};

export function requiredRule(message: any = i18n.t("global.item_required")): (value: any) => boolean | string {
  return (value) => !!value || value === 0 || message;
}

export function emailRule(message: any = i18n.t("global.email_not_valid")): (value: any) => boolean | string {
  return (value) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || message;
}

export function decimalRule(message: any = i18n.t("global.two_decimal_digits")): (value: any) => boolean | string {
  return (value) => /^([0-9]+(.[0-9]?[0-9]?)?)?$/.test(value) || message;
}
