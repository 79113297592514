






























































































































































































































import Vue from "vue";
import db from "@/firebase/db";
import fb from "firebase/app";
import DBHelper from "@/tscript/dbHelper";
import { i18ToSelect } from "@/tscript/utils/index";
import * as _ from "lodash";
import { DOMAIN_URL } from "@/config/index";
import { ZONE } from "@/config/index";
import { getCountriesList } from "@/components/admin/adminHelper";

// Components
import MycPageLayout from "@/layout/MycPageLayout.vue";
import MycCard from "@/components/global/cards/MycCard.vue";
import { mapActions, mapState } from "vuex";
import { requiredRule } from "@/components/admin/adminHelper";
import { getSpecialityList } from "@/components/helper/general";
import MycButton from "@/components/global/buttons/MycButton.vue";

export default Vue.extend({
  name: "Account",
  components: {
    MycPageLayout,
    MycCard,
    MycButton,
  },
  data() {
    return {
      valid: true,
      currentUser: {} as any,
      getParam: "",
      dbHelper: new DBHelper(db),
      loading: false,
      errorMsgFunction: "",
      successMsg: "",
      dialogSignUpForm: false,
      password: "",
      passwordConfirm: "",
      showPassword: false,
      showPasswordConfirm: false,
      basicRule: [requiredRule()],
      itemRules: [(v: any) => !!v || this.$i18n.t("global.item_required")],
      statusList: i18ToSelect({
        local: this.$i18n.t("global.local"),
        expatriate: this.$i18n.t("global.expatriate"),
      }),
      positionList: i18ToSelect({
        physiotherapist: this.$i18n.t("global.physioterapist"),
        nurse: this.$i18n.t("global.nurse"),
        paramedic: this.$i18n.t("global.paramedic"),
        doctor: this.$i18n.t("global.doctor"),
        secretary: this.$i18n.t("global.secretary"),
        manager: this.$i18n.t("global.manager"),
        coordinator: this.$i18n.t("global.coordinator"),
        other: this.$i18n.t("global.other"),
      }),
      rules: {
        required: (value: any) =>
          !!value || value === 0 || this.$i18n.t("global.item_required"),
        counter: (value: any) =>
          value?.length >= 8 ||
          this.$i18n.t("login.password_contains_characters"),
        specialChar: (value: any) => {
          const patternNumber = new RegExp("[#&@!*$%]");
          return (
            patternNumber.test(value) ||
            this.$i18n.t("login.password_contains_letters_and_numbers")
          );
        },
        number: (value: any) => {
          const patternNumber = new RegExp("[0-9]");
          return (
            patternNumber.test(value) ||
            this.$i18n.t("login.password_contains_letters_and_numbers")
          );
        },
        upper: (value: any) => {
          const patternNumber = new RegExp("[A-Z]");
          return (
            patternNumber.test(value) ||
            this.$i18n.t("login.password_contains_upper_and_lower_case")
          );
        },
        lower: (value: any) => {
          const patternNumber = new RegExp("[a-z]");
          return (
            patternNumber.test(value) ||
            this.$i18n.t("login.password_contains_upper_and_lower_case")
          );
        },
      },
    };
  },
  methods: {
    ...mapActions("user", ["setUserDataAction"]),
    ...mapActions("snackbar", ["setSnackbarAction"]),
    getCountriesList,
    getSpecialityList,
    goBack() {
      this.$router.go(-1);
    },
    async loadData() {
      if (this.getParam != "new") {
        if (this.userData?.id == this.getParam) {
          this.currentUser = await this.dbHelper.getDocFromCollection(
            "users",
            this.getParam
          );
        }
      }
    },

    async editAccountData() {
      const isFormUserValid = (
        this.$refs.personalForm as Vue & {
          validate: () => boolean;
        }
      ).validate();
      const isFormPasswordValid = (
        this.$refs.passwordForm as Vue & {
          validate: () => boolean;
        }
      ).validate();
      const isFormProfessionalValid = (
        this.$refs.professionalForm as Vue & {
          validate: () => boolean;
        }
      ).validate();
      if (isFormUserValid && isFormPasswordValid && isFormProfessionalValid) {
        if (this.password == this.passwordConfirm) {
          this.errorMsgFunction = "";
          const userToAdd: any = this.currentUser;
          userToAdd.last_update = new Date();
          userToAdd.password = this.password;
          let resultSave: any = null;
          this.loading = true;

          userToAdd.id = this.getParam;
          if (this.getParam == "new") {
            delete userToAdd.id;
            userToAdd.link = DOMAIN_URL;
            const createUserfunction = fb
              .app()
              .functions()
              .httpsCallable("createUser");
            resultSave = await createUserfunction(userToAdd).catch((error) => {
              this.errorMsgFunction = error;
            });

            // // TO Delete
            // userToAdd.password = "";
            // userToAdd["created_on"] = new Date();
            // const user = await this.dbHelper.addDataToCollection(
            //   "users",
            //   userToAdd
            // );
            // await this.dbHelper.addDataToCollection("network", {
            //   user_id: user.id,
            //   current: new Array(),
            //   waiting: new Array(),
            //   asking: new Array(),
            // });
            // //End delete
          } else {
            if (this.getParam) {
              delete userToAdd.id;
              resultSave = await this.dbHelper.setDataToCollection(
                "users",
                this.getParam,
                userToAdd
              );
              userToAdd.id = this.getParam;
              this.loadData();
            }
          }

          if (resultSave) {
            this.$store.dispatch("usersListAction", null);
            this.setSnackbarAction({
              status: true,
              message: this.$i18n.t("snackbar.data_saved"),
              type: "success",
            });
          } else {
            this.setSnackbarAction({
              status: true,
              message: this.$i18n.t("snackbar.error"),
              type: "error",
            });
          }
          this.loading = false;
          if (!this.errorMsgFunction) {
            const newUser = {
              ...(this.currentUser as any),
              ...this.userData,
            };
            this.setUserDataAction(newUser);
            this.goBack();
          }
        } else {
          this.errorMsgFunction = this.$i18n
            .t("global.error_password_matching")
            .toString();
        }
      } else {
        setTimeout(function () {
          const el = document.querySelector(
            ".v-messages.error--text:first-of-type"
          );
          el?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 500);
      }
    },
  },

  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("countries", ["countriesList"]),
  },
  async created() {
    this.getParam = this.$route.params.id;
    if (this.getParam != "new") {
      this.currentUser = await this.dbHelper.getDocFromCollection(
        "users",
        this.getParam
      );
    }
    this.loadData();
  },
});
