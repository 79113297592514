









import Vue from "vue";
export default Vue.extend({
  name: "myc-page-layout",
  props: ["name", "gap", "layout"],
  computed: {
    getNumberOfCol() {
      const layout: any = this.layout;
      if (layout?.length) {
        return layout?.length;
      } else {
        return layout;
      }
    },
  },
  methods: {
    getColSize(index: any) {
      if (this.layout?.length) {
        return "width: " + this.layout[index - 1] + "%;";
      } else {
        return "width: " + 100 / this.layout + "%";
      }
    },
  },
});
