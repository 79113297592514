import i18n from "@/i18n";
import * as _ from "lodash";

export const i18ToSelect = (obj: any, addAll: boolean = false) => {
  const newObj = new Array();
  if (addAll) {
    newObj.push({ value: "all", text: i18n.t("global.all") });
  }
  for (const key in obj) {
    newObj.push({ value: key, text: obj[key] });
  }
  return newObj;
};

export const getSpecialityList = (forSelect = false) => {
  let types: any = {
    anesthesia_resuscitation: i18n.t("speciality.anesthesia_resuscitation"),
    allergology: i18n.t("speciality.allergology"),
    arbovirose: i18n.t("speciality.arbovirose"),
    cardiology: i18n.t("speciality.cardiology"),
    covid_19: i18n.t("speciality.covid_19"),
    general_visceral_surgery: i18n.t("speciality.general_visceral_surgery"),
    surgery_orthopedic: i18n.t("speciality.surgery_orthopedic"),
    surgery_thoracic_cardiac_: i18n.t("speciality.surgery_thoracic_cardiac_"),
    surgery_pediatric: i18n.t("speciality.surgery_pediatric"),
    surgery_neuro: i18n.t("speciality.surgery_neuro"),
    surgery_plastic_burn: i18n.t("speciality.surgery_plastic_burn"),
    dermatology: i18n.t("speciality.dermatology"),
    endocrinology: i18n.t("speciality.endocrinology"),
    geriatrics: i18n.t("speciality.geriatrics"),
    obstetric_gynecology: i18n.t("speciality.obstetric_gynecology"),
    hematology: i18n.t("speciality.hematology"),
    hepato_gastro_enterology: i18n.t("speciality.hepato_gastro_enterology"),
    immunology: i18n.t("speciality.immunology"),
    infectious_diseases: i18n.t("speciality.infectious_diseases"),
    malaria: i18n.t("speciality.malaria"),
    medicine_general: i18n.t("speciality.medicine_general"),
    medicine_internal: i18n.t("speciality.medicine_internal"),
    medicine_physical_functional_rehabilitation: i18n.t(
      "speciality.medicine_physical_functional_rehabilitation"
    ),
    medicine_occupational: i18n.t("speciality.medicine_occupational"),
    medicine_emergency: i18n.t("speciality.medicine_emergency"),
    nephrology: i18n.t("speciality.nephrology"),
    neurology: i18n.t("speciality.neurology"),
    nutrition: i18n.t("speciality.nutrition"),
    oncology: i18n.t("speciality.oncology"),
    ophthalmology: i18n.t("speciality.ophthalmology"),
    ent_head_and_neck_surgery: i18n.t("speciality.ent_head_and_neck_surgery"),
    pharmacology: i18n.t("speciality.pharmacology"),
    pulmonology: i18n.t("speciality.pulmonology"),
    psychiatry: i18n.t("speciality.psychiatry"),
    radiology: i18n.t("speciality.radiology"),
    rheumatology: i18n.t("speciality.rheumatology"),
    odontology_dental_surgery: i18n.t("speciality.odontology_dental_surgery"),
    stomatology_maxillofacial_surgery: i18n.t(
      "speciality.stomatology_maxillofacial_surgery"
    ),
    traumatology: i18n.t("speciality.traumatology"),
    toxicology: i18n.t("speciality.toxicology"),
    virology: i18n.t("speciality.virology"),
    urology: i18n.t("speciality.urology"),
    other: i18n.t("speciality.other"),
  };
  if (forSelect) {
    types = i18ToSelect(types);
  }
  return types;
};

export const getGroupTypeList = (forSelect = false) => {
  let types: any = {
    public: i18n.t("group.public"),
    private: i18n.t("group.private"),
  };
  if (forSelect) {
    types = i18ToSelect(types);
  }
  return types;
};
