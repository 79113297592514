







































import Vue from "vue";
export default Vue.extend({
  name: "myc-card",
  data() {
    return {
      cssclass: "pl-4 pt-4",
      flatoption: false,
      scrollClass: "",
    };
  },
  props: {
    gap: {
      type: Number,
      default: 1,
    },
    nomargin: {
      type: Boolean,
      default: false,
    },
    nopadding: {
      type: Boolean,
      default: false,
    },
    noTitleMarginBottom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    customstyle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    notitle: {
      type: Boolean,
      default: false,
    },
    smalltitle: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    isflat: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  beforeMount: function () {
    if (this.isflat == true) this.flatoption = true;
  },
  created: function () {
    const noTitleMarginBottom = this.noTitleMarginBottom;
    if (this.color == "transparent" || this.noTitleMarginBottom == true) {
      this.cssclass = "";
      if (this.nomargin == true) this.cssclass = "ma-0 pt-4 pl-4";
      if (this.nopadding == true) this.cssclass = "mb-4 pa-0";
      this.flatoption = true;
    } else if (noTitleMarginBottom != true && this.title == null) {
      this.cssclass = "pa-4";
      if (this.nomargin == true) this.cssclass = "ma-0 pa-4";
      if (this.nopadding == true) this.cssclass = "pa-0";
      this.flatoption = true;
    } else {
      this.cssclass = "ma-4 pa-4";
      if (this.nomargin == true && this.nopadding == false)
        this.cssclass = "ma-0 pa-4";
      if (this.nopadding == true && this.nomargin == false)
        this.cssclass = "ma-4 pa-0";
      if (this.nomargin == true && this.nopadding == true)
        this.cssclass = "ma-0 pa-0";
    }
  },
  computed: {
    slotPassed() {
      if (this.$slots.buttonRight != undefined) return `mr-4`;
      return ``;
    },
    isMobile: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return false;
    },
  },
});
